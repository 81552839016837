/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

// import logger from './logger.js'
// logger.disableLogger();

import { Vue, settings, vueApp } from 'glib-web/index.js'
import './firebase.js'


// const reportError = (err) => {
//   const params = {
//     message: err.toString(),
//     stack: err.stack,
//     authenticity_token: Utils.dom.getCsrf()
//   }

//   const formData = new FormData();
//   for (const [key, value] of Object.entries(params)) {
//     formData.append(key, value);
//   }

//   fetch("/errors", {
//     method: "POST",
//     body: formData,
//     headers: {
//       'Accept': 'application/json'
//     }
//   })
// }

// // See https://stackoverflow.com/questions/52071212/how-to-implement-global-error-handling-in-vue
// Vue.config.errorHandler = (error, vm, info) => {
//   console.error("Vue error:", error);
//   reportError(error)
// }

// settings.errorHandler = (error, message) => {
//   console.error("GLib error:", message, error);
//   reportError(error)
// }

// window.addEventListener("error", (event) => {
//   const error = event.error

//   // Not needed. The original exception log would have been displayed anyway.
//   // console.error("Root error:", error);

//   reportError(error)
// })

settings.themes = {
  light: {
    dark: false,
    colors: {
      primary: "#1A4D6A",
      success: "#34BE4D",
      error: "#F13327",
      warning: "#FFA500",
      info: "#379FD7",
      // info: "#33B5E7"

      // green: "#FF5D6D"
    }
    // // primary: "#2b95ac",
    // primary: "#379FD7",
    // // warning: "#fb8c00",
    // warning: "#ff0000",
    // info: "379FD7"
  }
};
